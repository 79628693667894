<template>
	<div>
		<menuBar />
		<home id="home" />
		<strain id="strain" />
		<about id="about" />
		<contact id="contact" />

		<v-dialog
			v-model="popUp"
			content-class="modal"
		>
			<div class="modalContent">
				<v-img
					:src="$root.publicPath + 'image/slide3.jpg'"
					:lazy-src="$root.thumbImage + 'image/slide3.jpg'"
					class="popUpImage"
					@click="$router.push({path: '/wholesale'})"
				/>
				<v-btn
					icon
					class="white closeButton"
					@click="popUp = false"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</div>
		</v-dialog>
	</div>
</template>

<script>
import menuBar from './extended/menu.vue'
import home from './home.vue'
import strain from './strain.vue'
import about from './about.vue'
import contact from './contact.vue'

export default {
	components: {
		menuBar, home, strain, about, contact
	},
	data () {
		return {
			popUp: true
		}
	}
}
</script>

<style>
	.modal{
		width: 30%;
		z-index: 9999;
	}
	@media only screen and (max-width: 500px) {
		.modal{
			width: 80%;
		}
	}
</style>

<style scoped>
	#home, #about {
		background-color: rgb(243, 242, 242);
	}
	#strain {
		background-color: rgb(253, 209, 0);
	}
	#home, #strain, #about, #contact {
		width: 100vw;
	}
	.modalContent{
		position: relative;
	}
	.popUpImage{
		cursor: pointer;
		z-index: 9999;
		width: 100%;
	}
	.closeButton{
		position: absolute;
		top: 10px;
		right: 10px;
		z-index: 9999;
	}
	@media only screen and (max-width: 750px) {
		#home, #strain, #about, #contact {

		}
	}
</style>
