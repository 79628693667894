<template>
	<div class="strain">
		<div>
			<center class="judul pt-6">
				<v-img
					:src="$root.publicPath + 'image/logo_white.png'"
					width="35%"
					class="logo mt-n9"
				/>
				<span>STRAIN</span>
			</center>

			<v-row>
				<v-col
					v-for="(item, i) in produk"
					:key="i"
					class="produk cols-4"
				>
					<center>
						<div class="imageContent">
							<v-img
								:src="$root.publicPath + 'image/' + item.image"
								:lazy-src="$root.thumbImage + 'image/' + item.image"
								max-width="300"
							/>
							<v-img
								:src="$root.publicPath + 'image/' + item.icon"
								max-width="100"
								class="iconProduk"
							/>
						</div>
						<p class="px-9 mt-12">
							<b>{{ item.text }}</b>
						</p>
					</center>
				</v-col>
			</v-row>
		</div>

		<footer>
			<v-row justify="end">
				<div
					class="wholesaleButton"
					@click="$router.push({path: '/wholesale'})"
				>
					<v-icon
						color="#fdd100"
						class="mr-3"
					>
						mdi-checkbox-blank-circle
					</v-icon>
					Wholesale Kratom information click here
				</div>
			</v-row>
		</footer>
	</div>
</template>

<script>
export default {
	data () {
		return {
			produk: [
				{
					image: 'display_green.png',
					icon: 'strain_green.png',
					text: 'Green Vein Kratom Offers A Mild Energy Boost And Pleasing Rejuvenating Aroma. Considered The Mildest Of The Kratom Strains, Green Kratom Can Stand Alone Or Be Mixed With Both Red And White Vein Kratom Varieties For A More Robust Experience. Generally Considered One Of The Best Kratom Strains For Energy And Used To Help Increase Alertness And Focus, Green Vein Kratom Also Has A Soothing And Relieving Aroma.'
				},
				{
					image: 'display_red.png',
					icon: 'strain_red.png',
					text: 'Red vein kratom is the most widely available and best-selling strain of kratom on the U.S. market. Grown abundantly in Southeast Asia, red vein originates from mature kratom leaves that have a red stem and "veins" visible on the underside of the leaf. Red-veined kratom is world-renowned for its pleasant and relaxing aroma and is often praised as one of the best kratom strains for sleep and ethnobotanical pain relief.'
				},
				{
					image: 'display_white.png',
					icon: 'strain_white.png',
					text: 'White kratom is known as one of the best kratom strains for energy. Frequently praised for its uplifting and energizing aroma, white kratom is often used by those who need an extra boost in their concentration and stamina. Perfect for fighting fatigue and gaining an overall boost in energy, white kratom powder is great for those looking for an all-natural ethnobotanical stimulant.'
				}
			]
		}
	}
}
</script>

<style scoped>
	.strain{
		position: relative;
	}
	.judul{
		color: #204531;
		font-size: 30px;
		letter-spacing: 5px;
	}
	.judul span{
		position: relative;
		z-index: 99;
	}
	.logo{
		opacity: 0.3;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9;
	}
	footer{
		padding: 20px 60px;
		font-size: 20px;
		width: 100%;
		color: white;
		text-align: right;
		background-color: #204531;
		/* position: absolute;
		bottom: 0; */
		z-index: 9;
	}
	.produk{
		z-index: 9;
	}
	.produk p{
		font-size: 11px;
		color: #204531;
	}
	.imageContent{
		position: relative;
	}
	.iconProduk{
		position: absolute;
		bottom: -10%;
		left: 40%;
	}
	.wholesaleButton{
		cursor: pointer;
	}

	@media only screen and (max-width: 750px) {
		footer {
			padding: 15px 30px;
			font-size: 12px;
		}
	}
</style>
