<template>
	<div>
		<div class="icon">
			<v-col>
				<v-btn
					icon
					class="black"
					dark
				>
					<v-icon>mdi-instagram</v-icon>
				</v-btn>
			</v-col>
			<v-col class="my-n3">
				<v-btn
					icon
					class="black"
					dark
				>
					<v-icon>mdi-facebook</v-icon>
				</v-btn>
			</v-col>
			<v-col>
				<v-btn
					icon
					class="black"
					dark
				>
					<v-icon>mdi-pinterest</v-icon>
				</v-btn>
			</v-col>
		</div>
		<v-row
			align="center"
			class="ma-0"
		>
			<v-col
				class="pa-0"
				cols="12"
				xl="4"
				lg="4"
				md="4"
				sm="12"
				xs="12"
			>
				<v-carousel
					cycle
					hide-delimiters
					:show-arrows="false"
					height="100vh"
					style="width: 100%"
				>
					<v-carousel-item
						v-for="(item, i) in slide"
						:key="i"
					>
						<v-img
							:src="$root.publicPath + 'image/' + item"
							width="100%"
							height="100%"
							style="z-index: 99"
						/>
					</v-carousel-item>
				</v-carousel>
			</v-col>
			<v-col
				class="rightContent"
				cols="12"
				xl="8"
				lg="8"
				md="8"
				sm="12"
				xs="12"
			>
				<div class="buttonBottom">
					<v-row align="center">
						<v-col class="col-6">
							<v-img
								v-scroll-to="'#contact'"
								:src="$root.publicPath + 'image/phone.png'"
								width="80%"
								style="cursor: pointer"
							/>
						</v-col>
						<v-col class="col-6">
							<v-img
								:src="$root.publicPath + 'image/money.png'"
								style="cursor: pointer"
								@click="$router.push('/blog')"
							/>
						</v-col>
					</v-row>
				</div>
				<center>
					<div class="logo">
						<v-img
							:src="$root.publicPath + 'image/logo.png'"
							max-width="200px"
							class="pa-6"
						/>
					</div>
					<div class="logo my-6">
						<div class="content col-8 py-0">
							With a love for natural products, Kratom Alpha tries to bring a culture to protect nature, so nature will take care of us again. With quality raw materials, carefully picked and processed we also always make sure the goods arrive to you.
						</div>
					</div>
					<div class="logo">
						<div class="title">
							<v-col>
								<div class="garis" />
							</v-col>
							<v-col class="mt-n3">
								care for life and nature
							</v-col>
						</div>
					</div>
				</center>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	data () {
		return {
			slide: [
				'slide1.jpg',
				'slide2.jpg',
				'slide3.jpg'
			]
		}
	}
}
</script>

<style scoped>
.icon{
	position: absolute;
	top: 50px;
	left: 30px;
	z-index: 99;
}
.logo{
	color: rgb(102, 101, 101);
	line-height: 25px;
}
.garis{
	border-top: 3px solid black;
	width: 33%;
	float: right;
}
.buttonBottom{
	width: 200px;
	background-color: #204531;
	position: absolute;
	bottom: -80px;
	right: 0;
	border-radius: 20px 0 0 20px;
	padding: 0px 40px;
}
.rightContent{
	position: relative;
}
.content{
	font-size: 20px;
}
.title{
	text-align: right;
	color: #204531;
	font-size: 25px;
}

@media only screen and (max-width: 750px) {
	.rightContent{
		margin-top: 30px;
		margin-bottom: 100px;
	}
	.content{
		font-size: 15px;
	}
	.title{
		font-size: 15px;
	}
	.buttonBottom{
		top: auto;
		bottom: -80px;
	}
	.garis{
		width: 60%;
	}
	.icon {
		top: 30px
	}
}
</style>
